import { makeStyles, Typography } from "@material-ui/core"
import { graphql, PageProps } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../components/Layout"
import { Link } from "../components/Link"
import { PageContext } from "../interfaces/pageContext"

/* NOTE: Do not forget to change the revision accordingly. */
const REVISION = "Dezember 2019"

const useStyles = makeStyles((theme) => ({
  container: {
    counterReset: "h2",
    padding: theme.spacing(1),

    "& a": {
      textDecoration: "underline",
    },

    "& h2": {
      counterReset: "h3",
    },

    "& h2::before": {
      content: `counter(h2, upper-roman) ". "`,
      counterIncrement: "h2",
    },

    "& h3::before": {
      content: `counter(h3) ". "`,
      counterIncrement: "h3",
    },
  },
}))

export type TermsPageProps = PageProps<any, PageContext>

const TermsPage: React.FC<TermsPageProps> = (props) => {
  const classes = useStyles(props)
  const { data, location, pageContext } = props
  pageContext.title = "AGB"
  return (
    <Layout location={location} pageContext={pageContext}>
      <Helmet defer={false}>
        <meta name="robots" content={`noindex, noarchive, noimageindex`} />
      </Helmet>
      <div className={classes.container} id="container">
        <Typography align="center" variant="h1">
          Allgemeine Geschäftsbedingungen (AGB)
        </Typography>
        <section>
          <Typography align="center" variant="h2">
            Einleitung
          </Typography>
          <Typography paragraph={true}>
            Andreas Donig buybags.de (im Folgenden: „buybags.de“), Postanschrift: Bräugasse 10,
            94032 Passau betreibt unter der URL{" "}
            <Link href="/">{data.site.siteMetadata.siteUrl}</Link> eine Internet-Plattform (im
            Folgenden: „Website“) für Mode-Produkte.
          </Typography>
          <Typography paragraph={true}>
            <strong>HINWEIS: buybags.de verkauft selbst keine Produkte.</strong>
          </Typography>
          <Typography paragraph={true}>
            Die Verträge über den Kauf von Produkten werden unmittelbar bei den Partner Online-Shops
            (im Folgenden „Händler“ genannt) mit diesen Händlern abgeschlossen. buybags.de wird{" "}
            <strong>nicht</strong> Vertragspartei der abgeschlossenen Kaufverträge. In Bezug auf die
            Kaufverträge finden die AGB der entsprechenden Händler Anwendung.
          </Typography>
          <Typography paragraph={true}>
            Das Angebot von buybags.de ist für den Nutzer in jedem Fall kostenlos.
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Nutzerkreis
          </Typography>
          <Typography paragraph={true}>
            Die Website ist ausschließlich für private Nutzer bestimmt.{" "}
            <strong>
              Eine Nutzung der Website, oder der darauf angebotenen Funktionalitäten und
              Informationen für gewerbliche Zwecke jeglicher Art ist nicht gestattet.
            </strong>
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Inhalt des Services
          </Typography>
          <Typography paragraph={true}>
            buybags.de informiert die Nutzer der Website über Produkte führender Luxus- und
            Designer-Marken im Internet. buybags.de ist kein Händler, sondern aggregiert
            ausschließlich Angebote Dritter, die buybags.de den Nutzern strukturiert zur Verfügung
            stellt. Die Angebote werden von buybags.de unter bestimmten Gesichtspunkten geprüft.{" "}
            <strong>
              Für die Richtigkeit, Vollständigkeit und Aktualität und jeglicher darin mitgeteilten
              Informationen kann jedoch keinerlei Gewähr übernommen werden.
            </strong>{" "}
            Dies gilt insbesondere für Preis- oder Beschaffenheitsangaben, das Fehlen von
            Preisbestandteilen oder sonstigen Angaben, die nach den geltenden gesetzlichen
            Vorschriften vom Werbenden, also dem Dritten, z. B. dem Partner-Online-Shop zu machen
            sind. Auch enthält das Angebot der Website Links zu externen Webseiten Dritter, auf
            deren Inhalte buybags.de keinen Einfluss hat. Deshalb kann buybags.de auch für diese
            fremden Inhalte keinerlei Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
            ausschließlich der jeweilige Anbieter oder Seitenbetreiber verantwortlich.
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Nutzerpflichten
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              Jeder Nutzer ist verpflichtet, das in der Bundesrepublik Deutschland geltende Recht zu
              beachten. Es ist insbesondere untersagt, rechtsverletzende Inhalte oder Aussagen, z.
              B. in Blogs einzustellen. Dies umfasst jegliche herabsetzende Aussagen über Dritte,
              aber auch Marken, Waren, Dienstleistungen oder diese anbietende Unternehmen.
            </Typography>
            <Typography component="li" paragraph={true}>
              Der Nutzer stellt buybags.de von allen Ansprüchen Dritter frei, die wegen der von ihm
              eingestellten Inhalte, oder wegen des Nutzerverhaltens generell gegenüber buybags.de
              erhoben werden, einschließlich der dadurch verursachten, angemessenen Kosten der
              Rechtsverteidigung.
            </Typography>
            <Typography component="li" paragraph={true}>
              Aktivitäten, die darauf ausgerichtet sind, die Website funktionsuntauglich zu machen
              oder die Nutzung zu erschweren, sind verboten und können zivil- und strafrechtlich
              verfolgt werden. Untersagt sind insbesondere automatisierte Verfahren zum Abgreifen
              von Daten, wie Datencrawling und Datenscraping. buybags.de behält sich ausdrücklich
              vor, jeden Missbrauchsverdacht, insbesondere in Bezug auf Gutscheine, unter
              Ausschöpfung der rechtlichen Möglichkeiten zu untersuchen und gegebenenfalls zu
              verfolgen.
            </Typography>
            <Typography component="li" paragraph={true}>
              Die Website darf nicht für rechtswidrige Zwecke verwendet werden.
            </Typography>
            <Typography component="li" paragraph={true}>
              Der Nutzer dieses Internetangebots verpflichtet sich, die auf diesem Angebot
              angezeigten Fotos und Bilddateien nur zu privaten Zwecken zu verwenden und nicht
              anderweitig zu vermitteln, zu reproduzieren oder anderweitig zu nutzen.
            </Typography>
          </ul>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Gewerbliche Schutzrechte und Urheberrecht
          </Typography>
          <Typography paragraph={true}>
            An dieser Website und den darauf dargestellten Inhalten können gewerbliche Schutz- und
            Urheberrechte bestehen.{" "}
            <strong>
              Die Vervielfältigung, Verbreitung oder jede sonstige Art der Nutzung bzw. Verwertung
              außerhalb des rechtlich Zulässigen ist verboten.
            </strong>
            Alle Marken stehen im Eigentum des jeweiligen Inhabers.
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Datenschutz
          </Typography>
          <Typography paragraph={true}>
            Die Hinweise zum Datenschutz auf dieser Website finden Sie{" "}
            <Link href={`/datenschutz/`}>hier</Link>.
          </Typography>
        </section>

        <section>
          <Typography align="center" variant="h2">
            Haftung
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              buybags.de haftet unbeschränkt für vorsätzliches oder grob fahrlässiges Verhalten.
            </Typography>

            <Typography component="li" paragraph={true}>
              Für einfache Fahrlässigkeit haftet buybags.de nur, sofern wesentliche
              Vertragspflichten verletzt werden. Unter wesentlichen Vertragspflichten sind solche
              Pflichten zu verstehen, die der Vertrag nach seinem Sinn und Zweck dem Nutzer gerade
              zu gewähren hat, oder deren Erfüllung die ordnungsgemäße Durchführung des Vertrags
              überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen
              darf.
            </Typography>

            <Typography component="li" paragraph={true}>
              Die Haftung von buybags.de beschränkt sich im Falle leichter Fahrlässigkeit auf den
              vertragstypischen und vorhersehbaren Schaden.
            </Typography>

            <Typography component="li" paragraph={true}>
              Die Haftungsbeschränkungen bzw. -ausschlüsse nach den vorstehenden Ziffern 2 bis 4
              gelten nicht für eine gesetzlich vorgeschriebene verschuldensunabhängige Haftung von
              buybags.de, oder die Haftung aus einer vertraglich übernommenen
              verschuldensunabhängigen Garantie, sowie bei der Verletzung von Leib, Leben oder
              Gesundheit.
            </Typography>

            <Typography component="li" paragraph={true}>
              Soweit die Haftung von buybags.de ausgeschlossen oder beschränkt ist, gilt dies auch
              für die persönliche Haftung ihrer gesetzlichen Vertreter und Erfüllungsgehilfen. Die
              Haftungsbeschränkungen gelten ferner für alle Anspruchsarten, auch solche aus
              Deliktsrecht.
            </Typography>
          </ul>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Kündigung und Änderungen
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              Sowohl der Nutzer, wie auch buybags.de sind jederzeit ohne Einhaltung einer Frist
              berechtigt, das Nutzungsverhältnis auf Grundlage dieser AGB zu kündigen.
            </Typography>

            <Typography component="li" paragraph={true}>
              Bei Kündigung des Nutzungsverhältnisses werden erhobene Daten des Nutzers gelöscht,
              soweit für eine weitere Speicherung keine Rechtspflicht besteht.
            </Typography>

            <Typography component="li" paragraph={true}>
              buybags.de behält sich das Recht vor, die Inhalte und Struktur der Website jederzeit
              zu ändern.
            </Typography>

            <Typography component="li" paragraph={true}>
              buybags.de kann diese Bedingungen mit Wirkung für die Zukunft jederzeit ändern und
              anpassen. Soweit zwischen buybags.de und dem Kunden bereits ein Nutzungsverhältnis
              begründet wurde, gelten die geänderten Nutzungsbedingungen hierfür nur dann, wenn die
              Änderungen dem Kunden von buybags.de mitgeteilt wurden und der Geltung der geänderten
              Nutzungsbedingungen nicht binnen einem Monat ab Zugang der Mitteilung wenigstens in
              Textform (z. B. per E-Mail) widersprochen wurde, wobei die buybags.de mit der
              Mitteilung geänderter AGB auf diese Rechtsfolgen eines unterbliebenen Widerspruches
              hinweisen wird.
            </Typography>
          </ul>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Schlussbestimmungen
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              Diese Nutzungsbedingungen sind die allgemeinen Nutzungsbedingungen für buybags.de.
            </Typography>

            <Typography component="li" paragraph={true}>
              Die Europäische Kommission stellt unter{" "}
              <Link href="https://ec.europa.eu/consumers/odr/">
                https://ec.europa.eu/consumers/odr/
              </Link>{" "}
              eine Plattform zur Online-Streitbeilegung (OS) bereit. Zur Teilnahme an einem
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle ist der Anbieter
              nicht verpflichtet und nicht bereit.
            </Typography>

            <Typography component="li" paragraph={true}>
              Diese Nutzungsbedingungen und alle mit ihnen in Zusammenhang stehenden
              Rechtsstreitigkeiten und Ansprüche und der Vertragsschluss (sowie nicht-vertragliche
              Rechtstreitigkeiten und Ansprüche) unterliegen deutschem materiellen Recht. Das
              UN-Kaufrecht findet keine Anwendung. Haben Sie als Verbraucher im Zeitpunkt des
              jeweiligen Vertragsschlusses Ihren gewöhnlichen Aufenthaltsort in einem anderen Land
              innerhalb der Europäischen Union, bleibt die Anwendung zwingender Rechtsvorschriften
              jenes Landes von der in Satz 1 getroffenen Rechtswahl unberührt. Ausschließlicher
              Gerichtsstand ist Passau, soweit der Nutzer Kaufmann im Sinne des Handelsgesetzbuches
              ist, oder bei Klageerhebung keinen festen Wohnsitz in Deutschland hat.
            </Typography>

            <Typography component="li" paragraph={true}>
              Sollten einzelne Bestimmungen dieser Nutzungsbedingungen ganz oder teilweise unwirksam
              sein, berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.
            </Typography>

            <Typography component="li" paragraph={true}>
              Diese Nutzungsbedingungen sind in ihrer aktuellen Form unter der{" "}
              <Link href={`/agb/`}>{data.site.siteMetadata.siteUrl}/agb</Link> abrufbar.
            </Typography>
          </ul>
        </section>
        <section>
          <Typography paragraph={true}>
            Alte Versionen dieses Dokuments werden nicht gespeichert. Stand: {REVISION}
          </Typography>
        </section>
      </div>
    </Layout>
  )
}

export default TermsPage

export const query = graphql`
  query TermsPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
